@import "setup.css";

.indexpage {


	.subscribe-box {
		.form-control {
			margin: 10px 0 10px 0;
		}
	}

	.sylt-er-block {
		position: absolute;
		right: 0;
		top: 127px;
		width: 100%;
		text-align: right;

		@media (max-width:992px) {
			top: 105px;
		}

		@media (max-width:740px) {
			top: 121px;
		}

		@media (max-width:562px) {
			top: 130px;
		}

		@media (max-width:365px) {
			top: 150px;
		}

		.sylt-btn {
			border-radius: 0 0 5px 5px;
			padding: 15px;
			background-color: #1F393B;
			display: inline-flex;
			color: #d1b79d;
			text-align: left;
			width: 270px;
			text-decoration: none;
			z-index: 1;
			position: relative;

			span {
				margin-left: 15px;
				font-weight: bold;
				font-size: 14px;
			}

		}
	}

	.new-un-tag {
		position: absolute;
		top: 30px;
		font-size: 18px;
		background: var(--primary);
		z-index: 1;
		padding: 8px 15px;
		color: var(--white);
		border-radius: 0 5px 5px 0;
		text-transform: uppercase;

		span {
			font-family: var(--font-heading);

			i {
				font-size: 15.5px;
			}
		}
	}

	.banner-forms {
		position: relative;
		z-index: 3;

		@media (max-width: 767px) {
			position: unset;

		}



		.banner-subtitle {
			position: absolute;
			bottom: 5px;
			height: 45px;
			right: 10px;
			/*background: #3c3c3cd9;*/
			color: #fff;
			display: flex;
			align-items: center;
			padding: 15px;
			border-radius: 5px;
			line-height: normal;
			font-family: var(--font-heading);
			font-weight: 700;
			text-transform: uppercase;

			@media (max-width: 1199px) {
				bottom: unset;
				top: 570px;
				right: 5px;
			}

			@media (max-width: 767px) {
				bottom: unset;
				top: 450px;
				right: 15px;
			}
		}

		.search-form-area {
			background-color: rgba(255, 255, 255, 0.95);


			@media (min-width:1199px) {
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-radius: 10px;
				flex-flow: wrap;
				padding: 30px 30px 70px;
				position: relative;
			}

			>h3 {
				font-size: 22px;
				margin-bottom: 0;

				@media (max-width:1199px) {
					font-size: 26px;
				}
			}

			.search-btm-text {

				padding: 12px 10px;

				color: var(--white);
				text-align: center;
				background-color: var(--primary);
				font-family: var(--font-heading);

				@media (min-width:1199px) {
					position: absolute;
					bottom: 0;
					width: 100%;
					left: 0;
					border-radius: 0 0 10px 10px;
				}

				@media (max-width:1199px) {
					margin: 15px -15px 0;
				}

				a {
					font-weight: 700;
					color: var(--white);
					text-decoration: underline;

					&:hover {
						color: var(--black2);
					}
				}
			}

			.srh-form-right {
				@media (min-width:1199px) {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}
			}

			.form-flex {
				margin: 2px;

				@media (max-width:1199px) {
					width: 100%;
					padding: 6px 0;
				}

				.datepicker-trigger {
					width: 100%;
				}

				.form-bx-area {
					position: relative;

					&.slt-arrow {
						&:after {
							position: absolute;
							right: 9px;
							top: 15px;
							width: 0;
							height: 0;
							border-top: 4px dashed var(--primary);
							border-right: 4px solid transparent;
							border-left: 4px solid transparent;
							content: "";
						}
					}
				}

				.fa {
					position: absolute;
					left: 10px;
					top: 10px;
					color: #888e99;
					pointer-events: none;
				}


				.form-control {
					padding: 7px 5px 7px 32px;
					background-color: var(--white);
					border: none;
					border: 1px solid #c3c6cc;
					color: #888e99;
					border-radius: 5px;
				}

				>label {
					color: var(--black);
					font-size: 13px;
					text-transform: uppercase;
					display: block;
					font-weight: normal;
					letter-spacing: 1.5px;

					@media (max-width:992px) {
						display: none;
					}
				}
			}

			.form-btn {

				.btn {
					padding: 8px 12px;
					font-size: 14px;
				}
			}
		}
	}

	.banner {
		min-height: 440px;
		position: relative;
		background-size: cover;
		background-repeat: no-repeat;
		//background-position: center;
		background-position: 15% 30%;
		//background-position-y: 40px;
		height: 60vh;

		/* background-image: url(RESOURCE/img/banner-992.jpg); */
		/* background-image: url(RESOURCE/img/header-winter.jpg */
		/* background-image: url(RESOURCE/img/Sonnenaufgang_Braderup.jpg);*/
		/* background-image: url(RESOURCE/img/Wenningstedt_Mittsommernacht.webp); */
		/* background-image: url(RESOURCE/img/index_hero_westerland_hauptstrand.webp); */
		background-image: url(RESOURCE/img/munkmarsch banner.webp);




		@media (max-width:567px) {
			/* background-image: url(RESOURCE/img/banner-576.jpg); */
			/* background-image: url(RESOURCE/img/header-winter.jpg); */
			/* background-image: url(RESOURCE/img/Sonnenaufgang_Braderup.jpg);*/
			/* background-image: url(RESOURCE/img/Wenningstedt_Mittsommernacht.webp); */
			background-image: url(RESOURCE/img/munkmarsch banner.webp);
		}

		@media (max-width: 992px) {
			height: 625px;
			background-position-y: 100px;
		}

		@media (max-width: 767px) {
			height: 500px;
		}

		@media (min-width:993px) {
			//background-position-y: 20px;
			min-height: 620px;
			/*height:75vh;*/
			/* background-image: url(RESOURCE/img/bg-banner-home-neu-1.jpg);*/
			/* background-image: url(RESOURCE/img/header-winter.jpg);*/
			/* background-image: url(RESOURCE/img/Sonnenaufgang_Braderup.jpg);*/
			/* background-image: url(RESOURCE/img/Wenningstedt_Mittsommernacht.webp); */
			background-image: url(RESOURCE/img/munkmarsch banner.webp);
			/*background-position: 0 70px;*/


		}

		>.img-responsive {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}


		.intro {
			position: relative;
			z-index: 3;

			.hero {
				color: #fff;
				background: none;

				margin-bottom: 30px;
				padding-bottom: 0px;
				padding-top: 100px;


				h1 {
					font-size: 72px;
					font-weight: 600;
					margin: 30px 0 0;
					display: inline-block;
					padding-top: 15px;
					text-transform: uppercase;
					color: var(--whitecolor);

					@media (max-width:992px) {
						font-size: 40px;
						margin-top: 0;
					}

					@media (max-width:550px) {
						font-size: 36px;
					}
				}



			}

			.btn {
				min-width: 170px;
			}

		}



	}

	.banner-slide {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;

		.owl-item {
			position: relative;


		}

		.item {
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			display: flex;
			align-items: center;
			/*padding: 0 15px;*/
			width: 100%;
			justify-content: center;
			min-height: 560px;

			&.slide1 {
				background-image: url(RESOURCE/img/banner1.jpg);
			}

			&.slide2 {
				background-image: url(RESOURCE/img/banner2.jpg);
			}

			&.slide3 {
				background-image: url(RESOURCE/img/banner3.jpg);
			}

			&.slide4 {
				background-image: url(RESOURCE/img/banner4.jpg);
			}

		}

		.owl-nav {
			position: absolute;
			top: 40%;
			left: 3%;
			transform: rotateZ(90deg);
			margin-top: -35px;

			button {
				color: var(--white);
				font-size: 24px;
				width: 20px;
			}

			@media (max-width:992px) {
				display: none;
			}
		}

		.owl-dots {
			position: absolute;
			top: 50%;
			left: 4%;
			margin-top: -35px;

			@media (max-width:992px) {
				display: none;
			}


			.owl-dot {
				width: 3px;
				height: 3px;
				background-color: var(--white);
				margin-bottom: 25px;
				display: inherit;
				border-radius: 50%;
				position: relative;


				&.active {
					span {
						width: 20px;
						height: 20px;
						border: 1px solid #fff;
						display: inline-block;
						position: absolute;
						border-radius: 50%;
						left: -9px;
						top: -8px;

						&:after {
							content: "";
							width: 46px;
							height: .5px;
							background-color: #fff;
							background-color: var(--white);
							display: inline-block;
							position: absolute;
							top: 8px;
						}
					}
				}
			}
		}
	}



	.searchbar {
		@media (min-width:1200px) {
			position: absolute;
			width: 100%;
			bottom: 0;
			max-width: inherit !important;
			transform: translate(0, -50%);
		}

		@media (max-width:1199px) {
			padding-top: 15px;
			padding-bottom: 15px;
		}

		.best-price-bedge {
			position: absolute;
			top: -22vh;
			left: 0;

			@media (max-width:1199px) {
				top: 150px;
			}

			@media (max-width:480px) {
				//top: auto;
				bottom: 270px;
			}
		}

		.row {
			margin: 0;
		}

		.searchbox {

			.form-control {
				height: 40px;
			}

			input,
			.search-btn,
			select {
				border-radius: 0;
			}

			.search-btn {
				width: 100%;
			}

			.search-popup-trigger {
				cursor: pointer;
			}

			.form-control:disabled,
			.form-control[readonly] {
				background-color: white;
			}
		}


		@media (min-width:1200px) {
			max-width: 950px;
		}

	}

	.contact-section {
		background-color: #fff !important;
	}

	@media (max-width:767px) {

		.iheader {
			background: var(--introMobileImg) no-repeat center;
			-webkit-background-size: cover;
			background-size: cover;
			height: 100vh;

			.intro {
				.hero {
					h2 {
						font-size: 18px;
					}
				}
			}


		}
	}

	.logo-image-bl {
		padding: 30px;
		text-align: center;
		display: block;
	}

	.bl-index-title {
		min-height: 45px;
	}

	.best-price-bedge {
		position: absolute;
		left: 0;
		top: 160px;
		width: 100%;

		a {
			display: inline-block;
			transform: rotate(-10deg);
		}

		.tooltip.left {
			top: 70px !important;


			@media (max-width: 1500px) {
				top: 50px !important;

			}

			@media (max-width: 992px) {
				top: 55px !important;

			}

			@media (max-width: 767px) {
				top: 40px !important;

			}
		}

		@media (max-width: 992px) {
			top: 130px;
			bottom: unset;
		}

		@media (max-width: 800px) {
			top: 130px;
			bottom: unset;
		}

		@media (max-width: 767px) {
			top: 240px;
			bottom: unset;
			right: inherit;
			left: 0;
			text-align: left;
			max-width: inherit;
		}

		@media (max-height: 600px) {
			top: 170px;
		}

		img {
			max-width: 140px;
			width: 100%;

			@media (max-width: 992px) {

				width: 150px;
			}

			@media (max-width: 767px) {

				width: 110px;
			}

			@media (max-height: 600px) {
				max-width: 170px;
			}

			@media all and (min-width: 993px) and (max-width: 1500px) {
				max-width: 140px;
				bottom: -50px;
			}
		}
	}
}